import * as yup from "yup";
import { pt } from "yup-locale-pt";
yup.setLocale(pt);

import moment from "moment";
import "moment/locale/pt-br";

import IMask from "imask";

import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

import Alpine from "alpinejs";
import persist from "@alpinejs/persist";
import intersect from "@alpinejs/intersect";
import alpineExtensoes from "./gso/Scripts/lib/alpinehelpers";
import validadorSgi from "./gso/Scripts/lib/validadorformsgi";

import { saveAs } from "file-saver";

import "@phosphor-icons/web/regular";
import "@phosphor-icons/web/light";
import "@phosphor-icons/web/bold";
import "@phosphor-icons/web/fill";

import NProgress from "nprogress";
import "./gso/Content/nprogress.css";

Alpine.plugin(persist);
Alpine.plugin(intersect);
Alpine.plugin(alpineExtensoes);
Alpine.plugin(validadorSgi);

window.Alpine = Alpine;
window.addEventListener("load", function (event) {
  window.Alpine.start();
});

export { yup, moment, Alpine, IMask, tippy, saveAs, NProgress };

console.log("Bundling funcionando!");
