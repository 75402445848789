﻿export default function (Alpine) {
    Alpine.directive(
        "mask",
        (elemento, { expression }, { evaluateLater, effect }) => {
            let opcoesDaMascara = {
                mask: expression,
            };
            let imask = IMask(elemento, opcoesDaMascara);

            let getThingToLog = evaluateLater(expression);

            effect(() => {
                getThingToLog((valorMascara) => {
                    opcoesDaMascara.mask = valorMascara;
                    imask.destroy();
                    if (valorMascara != "") {
                        imask = IMask(elemento, opcoesDaMascara);
                    }
                });
            });
        }
    ).before("bind");
    Alpine.directive("dinheiro", (elemento) => {
        let opcoesDaMascara = {
            mask: "R$num",
            blocks: {
                num: {
                    mask: Number,
                    thousandsSeparator: ".",
                    scale: 2, // digits after decimal
                    signed: true, // allow negative
                    normalizeZeros: true, // appends or removes zeros at ends
                    radix: ",", // fractional delimiter
                    padFractionalZeros: true, // if true, then pads zeros at end to the length of scale
                },
            },
        };

        const mask = IMask(elemento, opcoesDaMascara);
    });
    Alpine.directive("pesquisavel", (elemento) => {
      elemento.setAttribute("data-live-search", true);
      elemento.setAttribute("data-live-search-style", "startsWith");
      const classesDoElemento = elemento.getAttribute("class");
      elemento.setAttribute("class", classesDoElemento + " selectpicker");
    });
  Alpine.directive(
    "baixar",
    async (elemento, { expression }, { evaluate, cleanup }) => {
      const handler = () => baixarArquivo(evaluate(expression));

      elemento.addEventListener("click", handler);

      cleanup(() => {
        elemento.removeEventListener("click", handler);
      });
    }
  );
  Alpine.directive(
    "abrir-pdf",
    async (elemento, { expression }, { evaluate, cleanup }) => {
      const handler = () => abrirPdfDoServidor(evaluate(expression));

      elemento.addEventListener("click", handler);

      cleanup(() => {
        elemento.removeEventListener("click", handler);
      });
    }
  );
  Alpine.magic("manage", () => (targetEl) => {
    const foundEl = document.querySelector(targetEl);

    if (!foundEl) {
      return;
    }

    return Alpine.$data(foundEl);
  });
}
