export default function (Alpine) {
  /* -------------------------------------------------------------------------- */
  /*                              STRING CONSTANTS                              */
  /* -------------------------------------------------------------------------- */
  /* ------------------- Named attributes used multiple times ------------------ */
  const DATA_ERROR = "data-error";
  const ERROR_MSG_CLASS = "error-msg";
  const PLUGIN_NAME = "validate";
  const VALIDATION_SCHEMA = "validation-schema";

  /* ----------------- These are just for better minification ------------------ */

  const REQUIRED = "required";
  const INPUT = "input";
  const CHECKBOX = "checkbox";
  const RADIO = "radio";
  const GROUP = "group";
  const FORM = "form";
  const FIELDSET = "fieldset";
  const notType = (type) => `:not([type="${type}"])`;
  const FIELD_SELECTOR = `${INPUT}${notType("search")}${notType(
    "reset"
  )}${notType("submit")},select,textarea`;
  const HIDDEN = "hidden";

  /* -------------------------------------------------------------------------- */
  /*                              Helper Functions                              */
  /* -------------------------------------------------------------------------- */

  const isHtmlElement = (el, type) => {
    const isInstanceOfHTML = el instanceof HTMLElement;
    return type ? isInstanceOfHTML && el.matches(type) : isInstanceOfHTML;
  };

  const includes = (array, string) =>
    Array.isArray(array) && array.includes(string);

  const addEvent = (el, event, callback) =>
    el.addEventListener(event, callback);

  const getAttr = (el, attr) => el.getAttribute(attr);

  const setAttr = (el, attr, value = "") => el.setAttribute(attr, value);

  const getEl = (el) =>
    isHtmlElement(el)
      ? el
      : document.getElementById(el) ||
        document.querySelector(`[name ="${el}"]`);

  const getForm = (el) => el && el.closest(FORM);

  const getName = (el) => getAttr(el, "name") || getAttr(el, "id");

  const getData = (strOrEl) => {
    const el = getEl(strOrEl);
    let data = formData.get(getForm(el));
    if (!data) return false;

    if (isHtmlElement(el, FORM)) return Object.values(data);
    if (isHtmlElement(el, FIELDSET))
      return Object.values(data).filter((val) => val.fieldset === el);
    if (isHtmlElement(el, FIELD_SELECTOR)) return data[getName(el)];
  };

  const getFormValues = (formEl) => {
    const formDataToValidade = formData.get(formEl);
    return Object.entries(formDataToValidade).reduce((result, [key, value]) => {
      const nodeType = value.node.type;
      const nodeName = value.node.getAttribute("name");

      let valueToSet = value.node._x_model?.get() ?? undefined;

      if (!valueToSet) {
        const inputsWithSameName = [
          ...formEl.querySelectorAll('input[name="' + nodeName + '"]'),
        ];
        const inputsChecked = inputsWithSameName.filter((val) => val.checked);
        if (nodeType === CHECKBOX) {
          const isMultipleCheckBox = inputsWithSameName.length > 1;
          valueToSet = isMultipleCheckBox
            ? inputsChecked.map((val) => val.value)
            : inputsChecked[0]?.value;
        }

        if (nodeType == RADIO && inputsChecked.length == 1) {
          const checkedRadio = inputsChecked[0];
          if (checkedRadio) valueToSet = checkedRadio.value;
        }

        if (!includes([RADIO, CHECKBOX], nodeType)) valueToSet = value.value;
      }

      result[key] = valueToSet;
      return result;
    }, {});
  };

  const isCallable = (fn) => typeof fn === "function";

  const isYupValidator = (schema) => !!schema && isCallable(schema.validate);

  function debounceAsync(inner, ms = 0) {
    let timer = null;
    let resolves = [];

    return function (...args) {
      // Run the function after a certain amount of time
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        // Get the result of the inner function, then apply it to the resolve function of
        // each promise that has been created since the last time the inner function was run
        const result = inner(...args);

        resolves.forEach((r) => r(result));
        resolves = [];
      }, ms);

      return new Promise((resolve) => resolves.push(resolve));
    };
  }

  /* -------------------------------------------------------------------------- */
  /*                       Validation Reactive Data Store                       */
  /* -------------------------------------------------------------------------- */

  const formData = new WeakMap();

  // non-reactive variable for modifiers on a per form basis
  const formModifiers = new WeakMap();

  const formSchemas = new WeakMap();

  /* -------------------------------------------------------------------------- */
  /*                           formData function                                */
  /* -------------------------------------------------------------------------- */

  function updateFieldData(field, data, triggerErrorMsg) {
    /**
     * data = {
     *  name: 'field id or name if no id',
     *  node: field,
     *  value:'field value',
     *  array:[optional used for groups],
     *  set: form node or fieldset node
     * }
     */
    const form = getForm(field);
    const name = getName(field);

    // only add data if has form and field name
    if (form && name) {
      // make sure form object exists
      if (!formData.has(form)) {
        formData.set(form, Alpine.reactive({}));
      }
      let tempData = formData.get(form);

      // Add any data from formData, then name, node, and value if it's not being passed along
      data = {
        ...tempData[name],
        name: name,
        node: field,
        value: field.value,
        validate: () => Promise.resolve(),
        ...data,
      };

      const fieldset = data.fieldset;

      // update required if not included
      data.required =
        data.required ||
        includes(data.mods, REQUIRED) ||
        includes(data.mods, GROUP) ||
        field.hasAttribute(REQUIRED);

      // check if disabled
      const disabled =
        field.hasAttribute("disabled") || fieldset?.hasAttribute("disabled");

      // run basic browser validity
      let valid = field.checkValidity();

      // if it is not disabled and passes browser validity then check using x-validate function
      if (!disabled && valid) {
        data.validate = debounceAsync(async () => {
          const formSchema = formSchemas.get(form);
          const valuesForm = getFormValues(form);
          await formSchema
            .validate(valuesForm, { abortEarly: false })
            .catch((result) => {
              const validationResultErrors = result.inner.filter(
                (val) => val.path == data.name
              );
              if (validationResultErrors.length == 0) return;

              const validationResultError = validationResultErrors[0];

              if (triggerErrorMsg)
                toggleError(field, false, validationResultError.errors);
              throw validationResultError;
            });
          if (triggerErrorMsg) toggleError(field, true);
        }, 5);
      }

      // update with new data
      tempData[name] = data;
      formData.set(form, tempData);
    }

    if (triggerErrorMsg)
      data
        .validate()
        .catch((validationResult) =>
          toggleError(field, false, validationResult.message)
        );
    return data;
  }

  function updateData(el, data, triggerErrorMsg) {
    if (isHtmlElement(el, FORM) || isHtmlElement(el, FIELDSET)) {
      const data = getData(el);
      data.forEach((item) => {
        return updateFieldData(item.node);
      });
    }
    if (isHtmlElement(el, FIELD_SELECTOR))
      return updateFieldData(el, data, triggerErrorMsg);
  }

  /* -------------------------------------------------------------------------- */
  /*                          Validate Magic Function                           */
  /* -------------------------------------------------------------------------- */
  let validateMagic = {};
  // Display reactive formData
  validateMagic.data = (el) => getData(el);
  validateMagic.formData = (el) => formData.get(getForm(getEl(el)));
  validateMagic.value = (el, value) => {
    el = getEl(el);
    const data = getData(el);
    if (!data) return false;

    // If data is array this el is a form or fieldset
    if (Array.isArray(data)) {
      return data.reduce((result, item) => {
        result[item.name] = item.value;
        return result;
      }, {});
    }

    // If value is passed than update the field and the formData; otherwise return value
    if (value) {
      data.value = value;
      el.value = value;
      updateFieldData(el);
    }
    return data.value;
  };

  // add or update formData
  validateMagic.updateData = (el, data, triggerErrorMsg) =>
    updateData(getEl(el), data, triggerErrorMsg);

  // toggle error message
  validateMagic.toggleError = (field, valid, message) =>
    toggleError(getEl(field), valid, message);

  // Check if form is completed and prevent default if not
  validateMagic.submit = function (e, successCallback) {
    let invalid = 0;
    const dataFromForm = getData(e.target);
    const validationEachField = dataFromForm.map((val) => {
      // double check validation
      val = updateData(val.node);
      return val.validate().catch((validationResult) => {
        invalid++;
        // focus on first invalid field
        if (invalid === 1) val.node.focus();
        toggleError(val.node, false, validationResult.message);
        e.preventDefault();
        // eslint-disable-next-line no-console -- this error helps with submit and is the only one that should stay in production.
        console.error(`${val.name}: ${validationResult.message}`);
        throw validationResult;
      });
    });
    Promise.all(validationEachField).then(() =>
      successCallback(getFormValues(e.target))
    );
  };

  validateMagic.submitAsync = async function (e) {
    let invalid = 0;
    const dataFromForm = getData(e.target);
    const validationEachField = dataFromForm.map((val) => {
      // double check validation
      val = updateData(val.node);
      return val.validate().catch((validationResult) => {
        invalid++;
        // focus on first invalid field
        if (invalid === 1) val.node.focus();
        toggleError(val.node, false, validationResult.message);
        e.preventDefault();
        // eslint-disable-next-line no-console -- this error helps with submit and is the only one that should stay in production.
        console.error(`${val.name}: ${validationResult.message}`);
        throw validationResult;
      });
    });
    return await Promise.all(validationEachField).then(() =>
      getFormValues(e.target)
    );
  };

  // isComplete works for the form as a whole and fieldsets using either the node itself or the id
  validateMagic.isComplete = (el) => {
    const data = getData(el);
    return Array.isArray(data)
      ? !data.some((val) => !val.valid)
      : data && data.valid;
  };

  // Main $validate magic function
  Alpine.magic(PLUGIN_NAME, () => validateMagic);
  // $formData magic function
  Alpine.magic("formData", (el) => formData.get(getForm(getEl(el))));

  Alpine.magic("formSchemas", (el) => formSchemas.get(getForm(getEl(el))));

  /* -------------------------------------------------------------------------- */
  /*                            x-required directive                            */
  /* -------------------------------------------------------------------------- */

  Alpine.directive(VALIDATION_SCHEMA, (el, { expression }, { evaluate }) => {
    if (expression) {
      const schema = evaluate(expression);
      if (isYupValidator(schema)) {
        const form = getForm(el);
        console.log(schema);
        formSchemas.set(form, schema);
      } else {
        console.log(
          "Aconteceu algo errado com o schema validador, ou validador não indentificado"
        );
      }
    }
  });

  /* -------------------------------------------------------------------------- */
  /*                            x-validate directive                            */
  /* -------------------------------------------------------------------------- */

  Alpine.directive(
    PLUGIN_NAME,
    (el, { modifiers, expression }, { evaluate }) => {
      const form = getForm(el);

      /* -------------------------------------------------------------------------- */
      /*                 If x-validate on <form> validate all fields                */
      /* -------------------------------------------------------------------------- */

      if (isHtmlElement(el, FORM)) {
        const elForm = el;
        // disable in-browser validation
        if (!modifiers.includes("use-browser")) {
          elForm.setAttribute("novalidate", true);
        }

        if (modifiers.includes("validate-on-submit")) {
          elForm.addEventListener("submit", function (e) {
            validateMagic.submit(e);
          });
        }

        // save all form modifiers
        formModifiers.set(form, modifiers);

        // bind reset with resetting all formData
        addEvent(elForm, "reset", () => {
          elForm.reset();
          const data = getData(elForm);
          // need a short delay for reset to take effect and reread values
          setTimeout(() => {
            data.forEach((field) => updateFieldData(field.node));
          }, 50);
        });

        const fields = elForm.querySelectorAll(FIELD_SELECTOR);
        fields.forEach((field) => {
          if (getName(field)) {
            updateFieldData(field, defaultData(field));
            // Don't add events or error msgs if it doesn't have a name/id or has x-validate on it so we aren't duplicating function
            // TODO: somehow detect if this is a group of checkboxes or radio buttons with required. Might need to run a forEach twice?
            if (
              !field
                .getAttributeNames()
                .some((attr) => attr.includes(`x-${PLUGIN_NAME}`))
            ) {
              addEvents(field);
            }
          }
        });
      }

      /* -------------------------------------------------------------------------- */
      /*      If x-validate on input, select, or textarea validate this field       */
      /* -------------------------------------------------------------------------- */

      // Only add if has name/id and and is field
      if (getName(el) && isHtmlElement(el, FIELD_SELECTOR)) {
        const formMods = formModifiers.has(form) ? formModifiers.get(form) : [];
        // include form level modifiers so they are also referenced
        modifiers = [...modifiers, ...formMods];
        // el is field element
        updateFieldData(el, defaultData(el));
        addEvents(el);
      }

      function defaultData(field) {
        const parentNode =
          field.closest(".field-parent") || includes(modifiers, GROUP)
            ? field.parentNode.parentNode
            : field.parentNode;
        const fieldset = field.closest(FIELDSET);

        // watch field and fieldset for changes to required or disabled
        watchElement(field);
        if (fieldset) watchElement(fieldset);

        return {
          mods: [...modifiers, field.type],
          fieldset: fieldset,
          parentNode: parentNode,
          exp: expression && evaluate(expression),
        };
      }

      // MutationObserver that watches for changes with required or disabled
      function watchElement(element) {
        // Create a new MutationObserver instance
        const observer = new MutationObserver((mutationsList) => {
          for (const mutation of mutationsList) {
            if (mutation.type === "attributes") {
              if (mutation.attributeName === "disabled") {
                updateData(element);
              }
            }
          }
        });

        // Start observing the element for attribute changes
        observer.observe(element, { attributes: true });

        // Return the observer instance in case you want to disconnect it later
        return observer;
      }

      /* -------------------------------------------------------------------------- */
      /*                  Directive Specific Helper Functions                       */
      /* -------------------------------------------------------------------------- */

      function addEvents(field) {
        addErrorMsg(field);
        const isClickField = includes([CHECKBOX, RADIO, "range"], field.type);
        const eventType = isClickField
          ? "click"
          : isHtmlElement(field, "select")
          ? "change"
          : "blur";
        addEvent(field, eventType, checkIfValid);

        if (includes(modifiers, INPUT) && !isClickField)
          addEvent(field, INPUT, checkIfValid);
      }

      /* -------------------------------------------------------------------------- */
      /*                           Check Validity Function                          */
      /* -------------------------------------------------------------------------- */

      function checkIfValid(e) {
        const field = this;
        const mods = getData(field).mods;

        /* --- Update formData with value and expression and trigger error message --- */
        const updatedData = updateFieldData(
          field,
          { exp: expression && evaluate(expression) },
          true
        );

        // add input event to blur events once it fails the first time

        updatedData.validate().catch((validationResult) => {
          if (!includes(mods, "bluronly") && e.type === "blur") {
            addEvent(field, INPUT, checkIfValid);
          }
          // refocus if modifier is enabled
          if (includes(mods, "refocus")) field.focus();
        });
      }
    }
  );
  /* ------------------------- End Validate Directive ------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                            Toggle Error Message                            */
  /* -------------------------------------------------------------------------- */

  function toggleError(field, valid, errorMessage) {
    const parentNode = getData(field).parentNode;

    const errorMsgNode = getErrorMsgFromId(field);
    errorMsgNode.textContent = errorMessage || "Testeee";

    /* ---------------------------- Set aria-invalid ---------------------------- */
    setAttr(field, "aria-invalid", !valid);

    /* ------------------ Check valid and set and remove error ------------------ */
    if (valid) {
      setAttr(errorMsgNode, HIDDEN);
      parentNode.removeAttribute(DATA_ERROR);
    } else {
      errorMsgNode.removeAttribute(HIDDEN);
      setAttr(parentNode, DATA_ERROR, errorMsgNode.textContent);
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                        Set Up Error Msg Node in DOM                        */
  /* -------------------------------------------------------------------------- */

  /* ------------ Helper function to find sibling error msg node -------------- */

  function findSiblingErrorMsgNode(el) {
    while (el) {
      // jump to next sibling element
      el = el.nextElementSibling;
      // return el if matches class name
      if (isHtmlElement(el, `.${ERROR_MSG_CLASS}`)) return el;
      // Stop searching if it hits another field
      if (isHtmlElement(el, FIELD_SELECTOR)) return false;
    }
    return false;
  }

  /* -------------- Helper function to get error msg node from id ------------- */

  function getErrorMsgFromId(field) {
    const name = getName(field);
    return document.getElementById(`${ERROR_MSG_CLASS}-${name}`);
  }

  /* ------ Function to setup errorMsgNode by finding it or creating one ------ */

  function addErrorMsg(field) {
    const name = getName(field);
    const errorMsgId = `${ERROR_MSG_CLASS}-${name}`;
    const fieldData = getData(field);

    // set targetNode. The span.error-msg typically appears after the field but groups assign it to set after the wrapper
    const targetNode = includes([CHECKBOX, RADIO], fieldData.node.type)
      ? fieldData.parentNode
      : field;

    /* --------------------- Find or Make Error Message Node -------------------- */

    // If there is an adjacent error message with the right id or class then use that. If not create one.
    const div = document.createElement("div");
    div.className = ERROR_MSG_CLASS;

    // If there already is an error-msg with the proper id in the form than use that
    const errorMsg = getErrorMsgFromId(field);

    const errorMsgNode = errorMsg || findSiblingErrorMsgNode(targetNode) || div;

    // add id tag and hidden attribute
    setAttr(errorMsgNode, "id", errorMsgId);
    setAttr(errorMsgNode, HIDDEN);

    // Add aria-errormessage using the ID to field
    setAttr(field, "aria-errormessage", errorMsgId);

    //  Only add element if it does not yet exist
    if (!getEl(errorMsgId)) targetNode.after(errorMsgNode);
  }
}
